import React from 'react'
import SEO from "../components/atoms/seo"
import { Link } from 'gatsby'
import posed from 'react-pose'
import styled from 'styled-components'
import FullPageSlide from '../components/organisms/FullPageSlide'
import ScrollDown from '../components/atoms/ScrollDown'
import BrandName from '../components/atoms/BrandName'
import About from '../components/organisms/sections/About'

const List = posed.ul({
  enter: { staggerChildren: 50 },
  exit: { staggerChildren: 20, staggerDirection: -1 },
})

const Item = posed.li({
  enter: { y: 0, opacity: 1 },
  exit: { y: 20, opacity: 0 },
})

const SectionStyled = styled.section`
  min-width: 100vw;
  min-height: 100vh;
`

const Background = styled.section`
  background: linear-gradient(45deg, transparent 50%, #b82601 50%);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`

function AboutPage() {
  return (
    <FullPageSlide slideNumber={1} prev={'/'} next={'/services/'}>
      <SEO
        title="About"
        keywords={[`about`, `soulweb`, `solutions`]}
      />

      <Background/>
      <BrandName/>

      <SectionStyled>
        <About/>
      </SectionStyled>

      {/*}<ScrollDown scrollTo={'/services/'}/>*/}
    </FullPageSlide>
  )
}

export default AboutPage
