import React, { Component } from 'react'
import posed, { PoseGroup } from 'react-pose'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

const Container = posed.div({
  enter: { staggerChildren: 200 },
  exit: { staggerChildren: 50 },
})

const ContainerWrapper = styled(Container)`
  display: flex;
`

const SubContainer = posed.div({
  enter: { x: 0, opacity: 1 },
  exit: { x: -50, opacity: 0 },
})

const SvgWrapper = styled(SubContainer)`
  overflow: hidden;
  .about-svg-icon {
    width: 100%;
  }
`

const TextWrapper = styled(SubContainer)`
  p {
    letter-spacing: 1.5px;
  }
`

class About extends Component {
  state = { isVisible: false };

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible
      });
    }, 2500);

  }

  render() {
    const { isVisible } = this.state;

    return(
      <PoseGroup>
        {isVisible
          ? <ContainerWrapper key="homeAbout" className={`min-h-full px-10 pt-16 md:p-20 lg:px-32 bg-grey-light lg:flex`}>
              <SvgWrapper className="hidden lg:block lg:flex-basis-1/3">
                <ReactSVG src="/images/about.svg" svgClassName="about-svg-icon" wrapper="span"/>
              </SvgWrapper>

              <TextWrapper className="lg:flex-basis-2/3 lg:pl-10">
                <h1 className={`text-white pb-10 font-mono text-2xl lg:text-4xl`}>About</h1>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  <strong>Open, friendly, vibrant.</strong> An hub of a network connecting professionals, students and professors, customers and suppliers.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  This is what <strong>Andrea</strong> e <strong>Marco</strong> envisioned in 2015, starting from their first web application development.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  The <strong>focus is on projects</strong> rather than on products, because every project is an adventure that integrates ideas, people and technologies.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  <strong>We work for the wellbeing of people and the planet.</strong> Profit is instrumental to share culture, wealth and innovation with the territory. To take care of the future.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  In the knowledge-based company <strong>everyone is an entrepreneur</strong> and uses his/her talent to develop business from within.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  <strong>The organization is horizontal and the relationships are based on trust rather than hierarchy.</strong> Knowledge workers grow by developing their competences, know-how, leadership and communication skills.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  School and work are connected. <strong>The company itself is a school of competencies.</strong> It fosters people to develop new skills and make their dreams come true.
                </p>

                <p className={`text-white pb-3 font-mono text-xs md:text-sm`}>
                  A place where <strong>different generations meet and design the future together.</strong> Undergraduate, graduate and postgraduate students, teachers and managers, experts in every field come together to share their knowledge and work on new, challenging projects.
                </p>
              </TextWrapper>
            </ContainerWrapper>
          : null
        }
      </PoseGroup>
    )
  }
}

export default About;
